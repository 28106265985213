.contact__container {
    grid-template-columns: repeat(1, max-content);
    justify-content: center;
    padding-bottom: 3rem;
}

.contact__title {
    text-align: center;
    font-size: var(--h3-font-size);
    font-weight: var(--font-medium);
    margin-bottom: var(--mb-1-5);
}

.contact__button {
    color: var(--text-color);
    font-size: var(--small-font-size);
    display: block;
    align-items: center;
    justify-content: center;
} 

.contact__button-icon {
    font-size: 1rem;
    transition: 0.3s;
}

.contact__button:hover .contact__button-icon {
    transform: translate(0.25rem);
}

.contact__form {
    width: 360px;
}

.contact__form-div {
    position: relative;
    margin-bottom: var(--mb-2);
    height: 4rem;
}

.contact__form-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid rgba(0, 0, 0, 0.3);
    background: none;
    color: var(--text-color);
    outline: none;
    border-radius: 0.75rem;
    padding: 1.5rem;
    z-index: 1;
}

.contact__form-tag {
    position: absolute;
    top: -0.75rem;
    left: 1.25rem;
    font-size: var(--smaller-font-size);
    padding: 0.25rem;
    background-color: var(--body-color);
    z-index: 10;
}

.contact__form-area {
    height: 11rem;
}

.contact__form-area textarea {
    resize: none;
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {

}
  
/* For medium devices */
@media screen and (max-width: 768px) {

    .contact__form {
        margin: 0 auto;
    }
}

@media screen and (max-width: 576px) {
    .contact__form {
        width: 270px;
    }
}

/* For small devices */
@media screen and (max-width: 350px) {
    .contact__form {
        width: 240px;
    }
}
  