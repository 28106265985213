.qualification__container {
    max-width: 1200px;
}


.qualification__sections {
    display: grid;
    grid-template-columns: 0.65fr;
    justify-content: center;
}


.qualification__data {
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    column-gap: 1.5rem;
}

.qualification__title {
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
}

.qualification__subtitle {
    display: inline-block;
    font-size: var(--small-font-size);
    margin-bottom: var(--mb-1);
}

.qualification__calender {
    font-size: var(--small-font-size);
}

.qualification__rounder {
    display: inline-block;
    width: 13px;
    height: 13px;
    background-color: var(--text-color);
    border-radius: 50%;
}

.qualification__line {
    display: block;
    width: 1px;
    height: 100%;
    background-color: var(--text-color);
    transform: translate(6px, -7px);
}

.qualification__data ul {
    list-style:circle;
}

.qualification__button {
    color: var(--title-color);
    font-size: var(--small-font-size);
    display: inline-flex;
    align-items: center;
    column-gap: 0.25rem;
    cursor: pointer;
    padding-left: 0.5rem;
}

.qualification__button-icon {
    font-size: 1rem;
    transition: 0.3s;
}

.qualification__button:hover .qualification__button-icon {
    transform: translateX(00.25rem);
}

.qualification__modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.211);
    z-index: var(--z-modal);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    opacity: 0;
    visibility: hidden;
    transition: .3s;
}

.qualification__modal-content {
    width: 800px;
    position: relative;
    background-color: var(--container-color);
    padding: 4rem 2rem 2rem;
    border-radius: 1.5rem;
}

.qualification__modal-close {
    position: absolute;
    top: 1.2rem;
    right: 1.5rem;
    font-size: 1.5rem;
    color: var(--title-color);
    cursor: pointer;
}

/* Active Modal */
.active-modal {
    opacity: 1;
    visibility: visible;
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 1200px) {
    .qualification__container {
        margin-left: auto;
        margin-right: auto;
    }

    .qualification__sections {
        grid-template-columns: 0.7fr;
    }

    .qualification__modal-content {
        width: 600px;
    }

    .qualification__modal-close {
        top: 1.2rem;
        right: 1.5rem;
    }
}
  
/* For medium devices */
@media screen and (max-width: 768px) {
    .qualification__container {
        margin-left: var(--mb-0-5);
        margin-right: var(--mb-0-5);
    }


    .qualification__sections {
        grid-template-columns: 0.8fr;
    }

    .qualification__modal-content {
        width: 450px;
        
    }
}

@media screen and (max-width: 576px) {

    .qualification__sections {
        grid-template-columns: initial;
        margin-left: var(--mb-1-5);
        margin-right: var(--mb-1);
    } 

    .qualification__modal-content {
        width: 400px;
        padding: 3rem 1.5rem 1.5rem;
    }

    .qualification__modal-close {
        top: .8rem;
        right: 1rem;
    }

}

/* For small devices */
@media screen and (max-width: 350px) {
    .qualification__title {
        font-size: var(--small-font-size);
        font-weight: var(--font-medium);
    }

    .qualification__subtitle {
        display: inline-block;
        font-size: var(--small-font-size);
        margin-bottom: var(--mb-1);
    }

    .qualification__container {
        margin-left: var(--mb-1);
        margin-right: var(--mb-0-25);
    }

    .qualification__sections {
        margin-left: var(--mb-0-5);
        margin-right: var(--mb-0-25);
    } 

    .qualification__data {
        gap: 0.5rem;
    }

    .qualification__modal-content {
        padding: 3rem 1rem 1rem;
        border-radius: 1rem;
    }

    .qualification__modal-close {
        top: 0.6rem;
        right: 0.6rem;
    }
}
  