.work__container {
  gap: 3rem;
  grid-template-columns: repeat(2, max-content);
  justify-content: center;
}

.work__filters {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.75rem;
  margin-bottom: var(--mb-2);
}

.work__item {
  cursor: pointer;
  color: var(--title-color);
  padding: 0.25rem 0.75rem;
  font-weight: var(--font-medium);
  border-radius: 0.5rem;
  text-transform: capitalize;
}

.work__item:hover {
  background-color: var(--title-color);
  color: var(--container-color);
}

.work__card {
  background-color: var(--container-color);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1.25rem;
  border-radius: 1rem;
}

.work__img {
  width: 400px;
  border-radius: 1rem;
  margin-bottom: var(--mb-1);
}

.work__title {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  margin-bottom: var(--mb-0-5);
}

.work__buttons {
  display: flex;
  gap: 1rem;
}

.work__button {
  width: max-content;
  color: var(--text-color);
  font-size: var(--small-font-size);
  display: flex;
  align-items: center;
  column-gap: 0.25rem;
  cursor: pointer;
}

.work__button-icon {
  font-size: 1rem;
  transition: 0.4s;
}

.work__button:hover .work__button-icon {
  transform: translateX(0.25rem);
}

.active-work {
  background-color: var(--title-color);
  color: var(--container-color);
}

/* Modal Styles */
.qualification__modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: var(--z-modal);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}

.qualification__modal-content {
  width: 700px;
  background-color: var(--container-color);
  padding: 2rem;
  border-radius: 1rem;
  position: relative;
  text-align: center;
}

.qualification__modal-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.5rem;
  color: var(--title-color);
  cursor: pointer;
}

.active-modal {
  opacity: 1;
  visibility: visible;
}

.modal__title {
  font-size: var(--large-font-size);
  margin-bottom: var(--mb-1);
}

.modal__description {
  font-size: var(--normal-font-size);
  color: var(--text-color);
  text-align: center;
  letter-spacing: 0.8px;
  line-height: 1.5;
}

.tech-icons {
  display: flex;
  margin-top: 0.6rem;
}

.tech-icon {
  font-size: 1.5rem;
}


/* Media Queries */
@media screen and (max-width: 992px) {
  .work__container {
    gap: 1.25rem;
  }

  .work__card {
    padding: 1rem;
  }

  .work__img {
    width: 360px;
    margin-bottom: 0.75rem;
  }
  

  .work__title {
    margin-bottom: 0.25rem;
  }
}

@media screen and (max-width: 768px) {
  .work__container {
    grid-template-columns: max-content;
  }
}

@media screen and (max-width: 576px) {
  .work__container {
    grid-template-columns: 1fr;
  }

  .work__img {
    width: 100%;
  }

  .qualification__modal-content {
    width: 90%;
  }
}

@media screen and (max-width: 350px) {
  .work__item {
    font-size: var(--small-font-size);
  }

  .work__filters {
    column-gap: 0.25rem;
  }

  .qualification__modal-content {
    width: 100%;
    padding: 1.5rem;
  }
}
