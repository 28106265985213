.home__container {
  row-gap: 7rem;
}

.home__content {
  grid-template-columns: 116px repeat(2, 1fr);
  padding-top: 5.5rem; 
  column-gap: 2rem;
  align-items: center;
}

.home__social {
  display: grid;
  grid-template-columns: max-content;
  row-gap: 1rem;
}

.home__social-icon {
  font-size: 1.25rem;
  color: var(--title-color);
}

.home__social-icon:hover {
  color: var(--title-color-dark);
}

.home__data {
  margin-right: var(--mb-1-5);
}

.home__title {
  font-size: var(--big-font-size);
  margin-bottom: var(--mb-0-25);
  margin-top: 0;
}


.home__subtitle {
  position: relative;
  font-size: var(--h3-font-size);
  padding-left: 5.4rem;
  font-weight: var(--font-normal);
  margin-bottom: var(--mb-1);
  display: flex;
}

.home__subtitle .dynamic-txts{
  margin-left: var(--mb-0-5);
  line-height: 1.8rem;
  height: 1.8rem;
  overflow: hidden;
}

.dynamic-txts li{
  top: 1.5px; /*adjust with the screen size*/
  position: relative;
  animation: slide 6s steps(2) infinite;
}

@keyframes slide{
  100%{
    top: -3.48rem;/*adjust with the screen size*/
  }
}

.dynamic-txts li::after {
  content: "";
  position: absolute;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: var(--body-color);
  border-left: 2px solid var(--text-color);
  animation: typing 3s steps(9) infinite;
}

@keyframes typing{
  40%,
  60% {
    left: calc(100% + 10px);
  }
  100% {
    left: 0;
  }
}

.home__subtitle::before {
  content: "";
  position: absolute;
  width: 70px;
  height: 1px;
  background-color: var(--text-color);
  left: 0;
  top: 1rem;
}

.home__description {
  max-width: 400px;
  margin-bottom: var(--mb-3);
}

.home__tech {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px; /* Space between the text "Tech:" and the icons */
  margin-top: 1rem; /* Additional spacing from the description to this tech line */
  margin-right: 1rem;
}

/* Original colors for each technology */
.tech-icon.html5 { color: #E34F26; } /* HTML5 */
.tech-icon.css3 { color: #1572B6; } /* CSS3 */
.tech-icon.javascript { color: #F7DF1E; } /* JavaScript */
.tech-icon.react { color: #61DAFB; }
.tech-icon.jquery { color: #0769AD; }
.tech-icon.git { color: #F05032; }
.tech-icon.bootstrap { color: #7952B3; }
.tech-icon.php { color: #777BB4; }
.tech-icon.postgresql { color: #336791; }
.tech-icon.wordpress { color: #21759B; }
.tech-icon.figma { color: #F24E1E; }

.tech-icon {
  font-size: 24px; /* Icon size, adjust as needed */
  color: var(--title-color-dark); /* Icon color, assuming you want them darker */
}

.home__img {
  background: url(../../assets//profile.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  box-shadow: inset 0 0 0 8px rgba(255, 255, 255, 30%);
  order: 1;
  justify-self: center;
  width: 400px;
  height: 400px;
  animation: profile__animate 8s ease-in-out infinite 1s; 
}

@keyframes profile__animate {
  0% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }

  50% {
    border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
  }

  100% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }
}

.home__scroll {
  margin-left: 9.25rem;
}

.wheel {
  animation: scroll 2s ease infinite;
}

@keyframes scroll {
  0% {
    transform: translateY(0);
  }

  30% {
    transform: translateY(3.75rem);
  }
}

.home__scroll-name {
  color: var(--title-color);
  font-weight: var(--font-medium);
  margin-right: var(--mb-0-25);
  margin-left: 2px;
}

.home__scroll-arrow {
  font-size: 1.25rem;
  color: var(--title-color);
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 1200px) {
  .home__container {
    row-gap: 3rem;
  }

  .home__content {
    grid-template-columns: 100px repeat(2, 1fr);
    column-gap: 1.25rem;
    padding-top: 2.75rem; 
  }

  .home__subtitle {
    padding-left: 3.75rem;
    margin-bottom: var(--mb-1);
  }

  .home__subtitle::before {
    width: 42px;
    top: 0.8rem;
  }

  .home__description {
    max-width: initial;
    margin-bottom: var(--mb-2-5);
  }

  .home__img {
    width: 300px;
    height: 300px;
    box-shadow: inset 0 0 0 8px rgb(255 255 255 / 30%);
  }

  .home__scroll {
    margin-left: 7.5rem;
  }

  .dynamic-txts li{
    top: -1px; /*adjust with the screen size*/
    position: relative;
    animation: slide 6s steps(2) infinite;
  }
  
  @keyframes slide{
    100%{
      top: -3.66rem;/*adjust with the screen size*/
    }
  }
}

/* For medium devices */
@media screen and (max-width: 768px) {
  .home__content {
    grid-template-columns: 0.5fr 3fr;
    padding-top: 3.5rem;
  }

  .home__img {
    order: initial;
    justify-self: initial;
    box-shadow: inset 0 0 0 2px rgba(0, 0, 0);
    width: 200px;
    height: 200px;
  }

  .home__data {
    grid-column: 1/3;
  }

  .home__scroll {
    display: none;
  }

  .dynamic-txts li{
    top: -0.7px; /*adjust with the screen size*/
    position: relative;
    animation: slide 6s steps(2) infinite;
  }
  
  @keyframes slide{
    100%{
      top: -3.65rem;/*adjust with the screen size*/
    }
  }
}

/* For small devices */
@media screen and (max-width: 350px) {
  .home__img {
    width: 180px;
    height: 180px;
  }

  .home__hand {
    width: 22px;
    height: 22px;
  }
}
